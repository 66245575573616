import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    marginTop: "40%",
    borderRadius: 20,
  },
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    marginBottom: theme.spacing(2),
  },
  pandaImage: {
    zoom: "35%",
  },
  buttom: {
    fontStyle: "italic",
    fontSize: "16px",
    marginTop: "12px",
  },
  textCenter: {
    marginTop: "4px",
    marginBottom: "8px",
    textAlign: "center",
    fontSize: "22px",
  },
  title: {
    marginBottom: "4px",
    textAlign: "center",
  },
}));
